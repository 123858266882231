<div class="who-we-are" id="whoWeAreSection">
    <div class="section-content">
        <br><br>
        <h1 id="whoWeAre">SOBRE VANDER RISK CONTROL</h1>
        <div class="text">
            <p class="justify">
                VANDER RISK CONTROL DE MEXICO S.A. DE C.V. es una empresa
                orgullosamente mexicana alineada a los estándares y requerimientos
                internacionales de sus clientes y socios comerciales. Somos una empresa líder
                en materia de seguridad industrial, salud ocupacional, medicina preventiva,
                evaluación y gestión integral de riesgos, ingeniería y aplicación de controles de
                riesgos, manejo de crisis, seguridad informática, prevención de riesgos
                laborales, protección al medio ambiente, protección civil, seguridad en
                procesos y mantenimiento inteligente aplicado a maquinaria y equipo en
                organizaciones e industrias. Estamos altamente comprometidos en ofrecer el
                máximo grado de eficiencia y resultados para con todos nuestros clientes.
                Somos expertos en cienclia aplicada a la prevención. <br><br>
                Somos especialistas en los sistemas integrados de prevención y protección y
                aseguramos la satisfacción de nuestros clientes. Somos su soporte y apoyo en
                el cumplimiento de los objetivos legales así como para la prevención y
                protección de sus bienes, activos, instalaciones, maquinarias, equipos, la vida
                humana, la comunidad civil y el ambiente promoviendo e impulsando en las
                organizaciones, industrias y empresas la gobernanza de un entorno
                organizacional humano, favorable, resiliente, preparado, sostenible y en
                conformidad con las regulaciones locales, nacionales e internacionales que les
                son aplicables en la materia. <br><br>
                En VANDER RISK CONTROL DE MEXICO S.A. DE C.V. la prevención es nuestra
                estrategia. Proteger es nuestra misión.
            </p>
            <br>
            <!-- <div style="text-align: center;">
                <p>
                    [ PROMOVEMOS EL TRABAJO ENFOCADO EN SISTEMAS INTEGRADOS DE GESTIÓN ]<br>
                    [ PROTEGEMOS LA SEGURIDAD Y SALUD DE LAS PERSONAS, EL MEDIO AMBIENTE Y LAS EMPRESAS ]<br>
                    [ CUIDAMOS LA VIDA HUMANA, SUS PROCESOS, MAQUINARIAS, INSTALACIONES Y MEDIO AMBIENTE ]<br> 
                    [ PROTEGEMOS LA INTEGRIDAD DEL SER HUMANO, SU INTERACCIÓN CON LA MÁQUINA Y SU ENTORNO ]<br>
                </p>
                <br>
                <p>
                    BRINDAMOS ASESORÍA, CONSULTORÍA & ATENCIÓN PARA LAS EMPRESAS E INDUSTRIAS EN: <br>
                    SEGURIDAD INDUSTRIAL | SALUD OCUPACIONAL | MEDICINA PREVENTIVA | EXÁMENES MÉDICOS PERIÓDICOS |
                    ZONA PROTEGIDA | SEGURIDAD SANITARIA | PROTECCIÓN VS INCENDIOS | PROTECCIÓN AMBIENTAL | CAPACITACIONES
                    PROTECCIÓN CIVIL | CONTROL DE CALIDAD | ANÁLISIS DE RIESGOS | ASESORÍA LEGAL STPS | GESTORÍA & TRAMITOLOGÍA
                </p>
            </div> -->
        </div>
    </div>
</div>