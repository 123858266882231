<div class="header" [ngClass]="headerClass">
    <div class="logo" (click)="goTo()"></div>
    <div class="sections">
        <ul class="desktop-menu">
            <li class="cat-services"><a href="https://vander.com.mx/public/Servicios_Vander_2025.pdf" download="Servicios 2025 - Vander.pdf" target="_blank"><span>CATÁLOGO DE SERVICIOS</span></a></li>
            <li [ngClass]="{'selected': sectionActive == 'whoWeAre'}" (click)="goTo('whoWeAre')">SOBRE VANDER RISK CONTROL</li>
            <li [ngClass]="{'selected': sectionActive == 'mvv'}" (click)="goTo('mvv')">MISIÓN, VISIÓN & VALORES</li>
            <li [ngClass]="{'selected': sectionActive == 'opr'}" (click)="goTo('opr')">NUESTROS PRINCIPIOS RECTORES</li>
            <!-- <li [ngClass]="{'selected': sectionActive == 'letter'}" (click)="goTo('letter')">CARTA ABIERTA A NUESTROS CLIENTES</li> -->
            <li [ngClass]="{'selected': sectionActive == 'ourServices'}" (click)="goTo('ourServices')">NUESTROS SERVICIOS</li>
            <li [ngClass]="{'selected': sectionActive == 'traceability'}" (click)="goTo('traceability')">TRAZABILIDAD, TRANSPARENCIA Y LEGALIDAD</li>
            <li [ngClass]="{'selected': sectionActive == 'contact'}" (click)="goTo('contact')">CONTACTO</li>
        </ul>
        <div class="mobile-menu" *ngIf="!menuOpened" (click)="openVerticalMenu()"><span class="material-icons">menu</span></div>
        <div class="mobile-menu" *ngIf="menuOpened" (click)="openVerticalMenu()"><span class="material-icons">menu_open</span></div>
    </div>
</div>