<div class="letter" id="letterSection">
    <div class="section-content">
        <!-- <div class="letter-content">
            <br><br>
            <h1 id="letter">CARTA ABIERTA A NUESTROS CLIENTES</h1><br>
            <div class="text-description">
                <p class="handwriting">Estimados clientes</p><br>
                <p class="justify">
                    Les enviamos un cordial y afectuoso saludo, esperando que se encuentren con bien. Agradecidos porque
                    se
                    encuentren
                    también con buen estado de salud e integridad en estos tiempos de dificultades, así como sus
                    familias y
                    todos sus seres
                    queridos.
                </p><br>
                <p class="justify">
                    En <b>VANDER RISK CONTROL DE MÉXICO S.A. DE C.V.</b> cuidamos en todo momento la integridad de
                    nuestras
                    acciones sabiendo de antemano que de nuestro trabajo dependen las vidas de sus empleados y
                    trabajadores.
                    Nuestros
                    servicios profesionales y de gran calidad humana aseguran a las empresas e industrias, como nuestros
                    clientes, la solución
                    integral y efectiva a sus problemas y necesidades.
                </p><br>
                <p class="justify">
                    Conociendo la responsabilidad que tienen nuestras acciones y nuestros servicios para velar por la
                    seguridad de la vida
                    humana y de sus bienes, así como del medio ambiente en el que vivimos, en cada uno de nuestros
                    servicios
                    y con cada uno
                    de nuestros clientes nos regimos por una estricta política de valores como la responsabilidad, la
                    confidencialidad, la
                    honestidad, el compromiso, el conocimiento de causa, el trabajo en equipo, la eficiencia, el sentido
                    humano, la integridad,
                    la creatividad y el espíritu de servicio. Proteger la vida humana, el ambiente y su empresa es
                    nuestro
                    compromiso.
                </p><br>
                <p class="justify">
                    Porque creemos que la colaboración hoy en día es más importante que nunca, la calidad de nuestro
                    trabajo, el
                    cumplimiento de los objetivos para con su empresa y los costos accesibles para todos nuestros
                    clientes,
                    son nuestra
                    garantía. Agradecidos por permitirnos trabajar con ustedes y seguros de que quedarán complacidos con
                    nuestro trabajo y
                    los resultados obtenidos en cada servicio y proyecto, quedamos a sus órdenes. Les enviamos un fuerte
                    abrazo.
                </p><br>
                <p class="handwriting">
                    Atentamente,
                </p><br>
                <div class="logo"></div>
                <p style="text-align: center;"><small>VANDER RISK CONTROL DE MÉXICO S.A. DE C.V.</small></p><br><br>
            </div>
        </div> -->
    </div>
</div>