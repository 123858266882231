<div class="contact" id="contactSection">
    <div class="section-content" id="contact">
        <br><br>
        <div>
            <h1>POLÍTICAS</h1>
            <ul>
                <li><a href="https://vander.com.mx/public/VRCM_Aviso_de_confidencialidad_2025.pdf"
                        download="VRCM Aviso de confidencialidad 2025.pdf" target="_blank">Aviso de
                        confidencialidad</a></li>
                <li><a href="https://vander.com.mx/public/VRCM_Aviso_de_trazabilidad_financiera_2025.pdf"
                        download="VRCM Aviso de trazabilidad financiera 2025.pdf" target="_blank">Aviso de
                        trazabilidad financiera</a></li>
                <li><a href="https://vander.com.mx/public/VRCM_Política_antilavado_2025.pdf"
                        download="VRCM Política antilavado 2025.pdf" target="_blank">Política
                        antilavado de la empresa</a></li>
            </ul>
        </div>
        <div>
            <h1>CONTACTO</h1>
            <form class="form">
                <input type="text" placeholder="Nombre" name="name" required [(ngModel)]="name" />
                <input type="text" placeholder="E-mail" name="email" required [(ngModel)]="email" />
                <input type="text" placeholder="Asunto" name="subject" required [(ngModel)]="subject" />
                <textarea name="msg" cols="30" rows="10" placeholder="Mensaje" required [(ngModel)]="message"></textarea>
                <div class="form-message"><small>Todos los campos son requeridos</small></div>
                <div class="form-message" *ngIf="error"><small class="error">Lo sentimos, ocurrió un error, intenta en un momento</small></div>
                <div class="contact-button">
                    <input type="button" value="Enviar" [disabled]="!name || !email || !subject || !message || !isValidEmail() || loading" *ngIf="!success" (click)="submit()"/>
                    <input type="button" class="success-btn" value="Mensaje enviado" disabled *ngIf="success"/>
                </div>
            </form>
        </div>
        <div class="contact-info">
            <h1>DATOS DE CONTACTO</h1>
            <ul>
                <li>Correo eléctronico:<br><a
                        href="mailto:info.servicios@vander.com.mx">info.servicios@vander.com.mx</a></li><br>
                <li>Teléfono:<br><a href="https://wa.me/528124225313" target="_blank" rel="noopener noreferrer"><div class="whatsapp"></div>&nbsp;<span>(+52) 81 2422 5313</span></a></li>
            </ul>
        </div>
    </div>
</div>