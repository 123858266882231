<div class="traceability" id="traceabilitySection">
    <div class="section-content">
        <br><br>
        <h1 id="traceability">TRAZABILIDAD, TRANSPARENCIA & LEGALIDAD EN NUESTROS SERVICIOS</h1>
        <div class="text">
            <p class="justify">
                Somos una empresa altamente comprometida con el cumplimiento positivo de nuestras
                obligaciones fiscales, códigos de ética empresarial y auditorías internas y externas dirigidas por
                nuestros clientes. <br><br>
                Solicite nuestro Número DUNS para corroborar nuestro compromiso con la legalidad y buena
                imagen como empresa responsable y comprometida con los requisitos legales aplicables.
                Estamos abiertos en todo momento a la implementación de auditorías por parte de nuestros
                clientes que corroboren nuestra solidez ética, responsabilidad y cumplimiento legal.
                En VANDER RISK CONTROL DE MÉXICO S.A. DE C.V. nos regimos por un estricto código de ética
                empresarial y un apego incansable a los requisitos legales y fundamentos regulatorios
                aplicables ante las dependencias municipales, estatales y federales para estar en conformidad
                con toda legislación vigente y dentro del completo marco de la legalidad en todas nuestras
                operaciones. <br><br>
                Cuidamos que todos nuestros servicios cuenten con trazabilidad, transparencia y legalidad en
                cada una de nuestras operaciones internas y con cada uno de nuestros clientes.
            </p>
        </div>
    </div>
</div>